<template>
  <v-footer
    height="auto"
    :app="$route.meta.footerApp"
    class="clear-background"
  >
    <v-container fluid  grid-list-lg class="pa-0 max-width-1200">
      <v-layout row wrap >
        <!-- <v-flex xs12 class="pa-0">
          <v-divider class="driveop-divider my-5"></v-divider>
        </v-flex> -->

        <v-flex xs12 class="centered-div">
              <v-img
                contain
                class="clickable footer-logo"
                :src="images.driveop_logo"
                :lazy-src="images.driveop_logo"
                @click="openLink('https://www.driveop.com')"
              ></v-img>
           
        </v-flex>

        <v-flex sm12 class="hidden-xs-only" mb-12>
          <v-layout justify-center row wrap justify-space-around mx-5>
                <v-btn depressed text class="antenna font-16 primary-color pa-0 ma-0" @click="$goTo('/about', 'about_us')">{{ $ml.get('footer_about') }}</v-btn>
                <v-btn depressed text class="antenna font-16 primary-color pa-0 ma-0" @click="$goTo('/solutions', 'business_solutions')">{{ $ml.get('footer_solutions') }}</v-btn>
                <v-btn depressed text class="antenna font-16 primary-color pa-0 ma-0" @click="$goTo('/process', 'process')">{{ $ml.get('footer_process') }}</v-btn>
                <v-btn depressed text class="antenna font-16 primary-color pa-0 ma-0" @click="$goTo('/portfolio', 'portfolio')">{{ $ml.get('footer_portfolio') }}</v-btn>
                <v-btn depressed text class="antenna font-16 primary-color pa-0 ma-0" @click="$goTo('/contactUs', 'contact')">{{ $ml.get('footer_contact') }}</v-btn>
          </v-layout>
        </v-flex>

      </v-layout>

      <v-layout row wrap justify-center> 
        <v-flex md4 pa-0></v-flex>
            <v-flex md4 style="text-align:center">
              <p class="avenir font-12 primary-color">{{$ml.get('footer_text')}}</p>
            </v-flex>
            <v-flex md4 mb-8>
              <v-layout row wrap class="mx-5" style="display: flex; justify-content: flex-end;">
                <v-flex xs12 style="display: flex; justify-content: flex-end;"><p class="antenna font-16 primary-color mb-0">Follow us</p></v-flex>
                <v-img v-for="(social, i) in social_media" :key="i" :src="social.image" :lazy-src="social.image" max-width="30px" class="mx-1 img-btn" @click="openLink(social.url)" />
              </v-layout>
            </v-flex>
    </v-layout>


  <!-- <v-layout row wrap>
     <v-flex xs6 style="display: flex; align-items: flex-end;">
          <p
            v-if="$vuetify.breakpoint.smAndUp"
            :class="{
              'px-4': $vuetify.breakpoint.xsOnly
            }"
            class="font-12 mb-0 primary-color"
          >This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener">Terms of Service</a> apply.</p>
      </v-flex>
       <v-flex xs6 style="display: flex; justify-content: flex-end;">
           <v-btn-toggle
            v-model="selectedLanguage"
            @change="setLanguage"
            class="transparent hidden-shadow"
            mandatory
          >
            <v-btn color="transparent" depressed :value="lang" v-for="lang in $ml.list" :key="lang">
              <v-img
                :aspect-ratio="16/9"
                height="30"
                width="30"
                max-width="30"
                contain
                :src="images[lang]"
                :lazy-src="images[lang]"
              ></v-img>
            </v-btn>
          </v-btn-toggle>
        </v-flex>
  </v-layout> -->

      <!-- <v-layout row wrap v-if="$vuetify.breakpoint.xsOnly">
        <v-flex sm6 xs12>
          <p
            :class="{'px-4': $vuetify.breakpoint.xsOnly}"
            class="primary--text font-12 mb-0 primary-color"
          >This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
        </v-flex>
    </v-layout> -->
      
    

    </v-container>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-footer>
</template>

<script>

export default {
  data () {
    return {
      images: {
        es: require('../assets/img/flags/mx.svg'),
        // pt: require('../assets/img/flags/br.svg'),
        en: require('../assets/img/flags/us.svg'),
        divider_line: require('../assets/img/images/divider-line.png'),
        driveop_white: require('../assets/img/sponsors/powered-white.png'),
        driveop_black: require('../assets/img/sponsors/powered-black.png'),
        driveop_logo: require('../assets/img/brand/logo.png'),
      },
      social_media: {
        linkedin: {
          image: require('../assets/img/icons/linkedin.png'),
          event: 'contact_goto_twitter',
          url: 'https://www.linkedin.com/company/drive-op/'
        },
        facebook: {
          image: require('../assets/img/icons/facebook.png'),
          event: 'contact_goto_facebook',
          url: 'https://www.facebook.com/DriveOpInternational/'
        },
        instagram: {
          image: require('../assets/img/icons/instagram.png'),
          event: 'contact_goto_instagram',
          url: 'https://www.instagram.com/drive.op/'
        },
        cb: {
          image: require('../assets/img/icons/cb.png'),
          event: 'contact_goto_instagram',
          url: 'https://www.crunchbase.com/organization/driveop'
        },
      },
      selectedLanguage: ''
    }
  },
  created () {
    this.selectedLanguage = localStorage.language
  },
  methods: {
    setLanguage () {
      this.g_isLoading = true
      this.$ml.change(this.selectedLanguage)
      window.localStorage.setItem('language', this.selectedLanguage)
      window.location.reload()
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    openLinkSocial (social) {
      this.$analytics(social.event)
      window.open(social.url, '_blank')
    }
  }
}
</script>

<style scoped>
.img-btn:hover {
  opacity: .5;
  cursor: pointer;
}
.footer-logo {
  height: 120px;
  max-width: 200px;
}

/***********/
/* xsOnly */
/***********/
@media screen and (max-width: 600px) {
  .footer-logo {
  height: 160px;
  max-width: 300px;
}
}
</style>
